import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import LayoutNew from "../../components/layout-new";
import Seo from "../../components/seo"
import JotformWithGTMQualify from "../../components/common/JotformWithGTMQualify";

const PageQualify = () => {
  const data = useStaticQuery(graphql`
    query PageQualifyQuery {
      prismicQualifyFormPage {
        data {
          page_title
          page_short_description
          jotform_url
        }
      }
    }
  `)

  const doc = data.prismicQualifyFormPage

  return ( 
    <LayoutNew>
      <Seo
        title={doc.data.page_title}
        description={doc.data.page_short_description}
      />

      <div className="pt-14 xl:pt-20">
        <JotformWithGTMQualify />
      </div>
    </LayoutNew>
  )
}

export default PageQualify
